import React, {useEffect} from 'react'
import styled from 'styled-components'

import H2 from '/src/components/library/H2'
import Body from '/src/components/library/Body'
import Button from '/src/components/library/Button'
import {navigate} from "gatsby";

const AboutCompany = ({data}) => {
    useEffect(() => {
    })
    return (
        <StyledAboutCompany>
            <Container>
                <Image img={`${process.env.GATSBY_STRAPI_API_URL}${data.Image.url}`}/>
                <TextWrapper>
                    <Text>
                        <H2>{data.Title}</H2>
                        <Body>{data.Text}</Body>
                        <Button label={'Learn More'} onClick={() => navigate('/about-us')}/>
                    </Text>
                </TextWrapper>
            </Container>
        </StyledAboutCompany>
    )
}

export default AboutCompany

const StyledAboutCompany = styled.div`
  padding-top: 9rem;
  padding-bottom: 9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const Container = styled.div`
  padding-right: 2.4rem;
  padding-left: 2.4rem;

  max-width: ${({theme}) => theme.layout.xxLargeScreen};
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    flex-direction: column;
  }

  @media ${({theme}) => theme.breakpoints.desktops_reverse_news} {
    padding: 6rem 2.4rem;
  }
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding: 0;
    padding-bottom: 1.4rem;
    //padding-bottom: 5rem;
  }
`

const Image = styled.div`
  flex: 1;
  border-radius: 12px;
  background-image: url(${({img}) => img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 600px;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    flex: unset;
    margin-bottom: 2rem;
  }
  @media ${({theme}) => theme.breakpoints.mobile} {
    border-radius: unset;
    margin-bottom: 2.4rem;
    height: 300px;
  }
`

const TextWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    flex: unset;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding: 0 1.6rem;
  }
`

const Text = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  max-width: 47.8rem;

  button {
    width: fit-content;
  }

  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    max-width: unset;
  }
  @media ${({theme}) => theme.breakpoints.mobile} {
    gap: 1.4rem;
  }
`
