import React from 'react'
import styled from 'styled-components'
import H4 from '/src/components/library/H4'

const SectorCard = ({img, txt}) => {
    return (
        <StyledSectorCard img={img}>
            <TextWrapper>
                <H4>{txt}</H4>
            </TextWrapper>
        </StyledSectorCard>
    )
}

export default SectorCard

const StyledSectorCard = styled.div`
  flex: 1;
  // cursor: pointer;
  position: relative;
  border-radius: 12px;
  background-image: url(${({img}) => img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 175px;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex: unset;
    width: 100%;
  }
`

const TextWrapper = styled.div`
  position: absolute;
  width: 80%;
  left: 2rem;
  bottom: 2rem;
  color: white;
`
