import React from 'react'
import styled from 'styled-components'

const Businesses = ({active, businesses}) => {

    return (
        <StyledBusinesses active={active}>
            {
                businesses.map((business, index) => {
                    // setPosition(position + 1)
                    return (
                        <Div key={index}>
                            <Img src={`${process.env.GATSBY_STRAPI_API_URL}${business.node.Image.url}`} alt={''}/>
                        </Div>
                    )
                })
            }
        </StyledBusinesses>
    )
}

export default Businesses

const StyledBusinesses = styled.div`
  height: 37.5rem;
  width: 100%;
  margin: 0 0 2.4rem 0;
  display: ${({active}) => active ? 'grid' : 'none'};
  grid-template-columns: repeat(3, 1fr);
  row-gap: 8rem;
  justify-content: space-between;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    //height: unset;
    grid-template-columns: repeat(2, 1fr);
  }
`

const Div = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    justify-content: center;
  }
`

const Img = styled.img`
  width: 150px;
  height: auto;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    width: 100px;
  }
`

// const Image = styled.div`
//   background-image: url(${({img}) => img});
//   background-size: contain;
//   background-position: center;
//   background-repeat: no-repeat;
//   max-width: 33vw;
//   height: auto;
// `
