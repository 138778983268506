import React from 'react'
import styled from 'styled-components'

import H4 from '/src/components/library/H4'

const ButtonTab = (props) => {

    return (
        <>
            {props.active &&
                <>
                    <StyledButtonTabActive onClick={props.onClick}>
                        <H4>
                            {props.label}
                        </H4>
                        <Line/>
                    </StyledButtonTabActive>
                </>
            }
            {!props.active &&
                <StyledButtonTabDeactivate onClick={props.onClick}>
                    <H4>
                        {props.label}
                    </H4>
                </StyledButtonTabDeactivate>
            }
        </>

    )
}

export default ButtonTab

const StyledButtonTabActive = styled.button`
  cursor: pointer;
  position: relative;
  border: none;
  text-decoration: transparent;
  background: none;
  opacity: 1;
  color: ${({theme}) => theme.colors.card};
`

const StyledButtonTabDeactivate = styled.button`
  cursor: pointer;
  border: none;
  position: relative;
  text-decoration: transparent;
  background: none;
  color: ${({theme}) => theme.colors.black};
  opacity: .37;
  
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    top: 112%;
    left: 0;
    background-color: ${({theme}) => theme.colors.black};
    opacity: .37;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

`

const Line = styled.div`
  position: absolute;
  border-bottom: 2px solid ${({theme}) => theme.colors.card};
  top: 112%;
  width: 100%;
`
