import React, {useState} from 'react'
import styled from 'styled-components'

import ButtonTab from "/src/components/library/ButtonTab";
import H2 from '/src/components/library/H2'
import Body from '/src/components/library/Body'

import Button from '/src/components/library/Button'
import {navigate} from 'gatsby'
import Sector from "/src/components/sector/Sector";
import Businesses from "/src/components/businesses/Businesses";

const BusinessPortfolio = ({ data, sector, businesses }) => {
    const [activeFirst, setActiveFirst] = useState(true)
    const [activeSecond, setActiveSecond] = useState(false)

    function setButtonActive(position) {
        if (!position) {
            setActiveFirst(true)
            setActiveSecond(false)
        } else {
            setActiveFirst(false)
            setActiveSecond(true)
        }
    }

    return (
        <StyledBusinessPortfolio>
          <Wrapper>
            <TextWrapper>
                <H2>{data.Title}</H2>
                <Body>{data.Text}</Body>
            </TextWrapper>
            <ButtonWrapper>
                <ButtonTab label={'Sector'} onClick={() => setButtonActive(0)} active={activeFirst}/>
                <ButtonTab label={'Businesses'} onClick={() => setButtonActive(1)} active={activeSecond}/>
            </ButtonWrapper>
            <Sector sector={sector} active={activeFirst}/>
            <Businesses businesses={businesses} active={activeSecond}/>
            <ActionWrapper>
                <Button label={'View Full Portfolio'} onClick={() => {
                navigate('/business-portfolio')}}/>
            </ActionWrapper>
          </Wrapper>
        </StyledBusinessPortfolio>
    )
}

export default BusinessPortfolio
const Wrapper = styled.div`
        max-width: ${({ theme }) => theme.layout.xxLargeScreen};
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: start;
    justify-content: center;
      padding: 5rem 2.4rem 13.6rem 2.4rem;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    padding: 5rem 3.2rem 5rem 2.4rem;
  }
    `

const StyledBusinessPortfolio = styled.div`
display: flex;
  justify-content: center;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2.4rem;
  margin-bottom: 5.2rem;
  p {
    max-width: 70.4rem;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 6.7rem;
  gap: 7.8rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-bottom: 4.3rem;
  }
`

const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    justify-content: center;
  }
`
