import {graphql, useStaticQuery} from "gatsby";
import React from 'react'
// import bg from "../../static/images/hero/image/bg.jpg";
// import bgMobile from "../../static/images/hero/image/bgMobile.jpg";
import styled from 'styled-components'

import Hero from "../components/hero/Hero.js";
import Layout from '../components/layout/en/Layout'
import Seo from '../components/seo'
import LatestNews from "../components/latest-news/LatestNews";
import AboutCompany from "../components/about-company/AboutCompany";
import BusinessPortfolio from "/src/components/business-portfolio/BusinessPortfolio";

const HomePage = (props) => {

    const {strapiHomePage, allStrapiLatestNews, strapiAboutCompany, strapiBusinessPortfolioComponent, allStrapiSectors, allStrapiBusinesses} = useStaticQuery(query);

    return (
        <Layout path={props.location.pathname}>
            <Seo
                title={strapiHomePage.metaTitle}
                description={strapiHomePage.metaDescription}
                keywords={strapiHomePage.metaKeywords}/>
            <HeroWrapper>
                <Hero path={props.location.pathname} img={`${process.env.GATSBY_STRAPI_API_URL}${strapiHomePage.backgroundDesktop?.url}`} strapiHomePage={strapiHomePage}/>
            </HeroWrapper>
            <HeroWrapperMobile>
                <Hero path={props.location.pathname} img={`${process.env.GATSBY_STRAPI_API_URL}${strapiHomePage.backgroundMobile?.url}`} strapiHomePage={strapiHomePage}/>
            </HeroWrapperMobile>
            <LatestNews data={allStrapiLatestNews.edges}/>
            <AboutCompany data={strapiAboutCompany}/>
            <BusinessPortfolio data={strapiBusinessPortfolioComponent} sector={allStrapiSectors.edges} businesses={allStrapiBusinesses.edges}/>
        </Layout>
    )
}

export default HomePage

const HeroWrapperMobile = styled.div`
        display: none;
      @media ${({theme}) => theme.breakpoints.mobile} {
        display: block;
      }
`

const HeroWrapper = styled.div`
        display: block;
      @media ${({theme}) => theme.breakpoints.mobile} {
        display: none;
      }
`

const query = graphql`
    query {
        strapiHomePage(locale: {eq: "en"}) {
            metaTitle
            metaDescription
            metaKeywords
            title
            title2
            title3
            subtitle
            backgroundDesktop {
              url
            }
            backgroundMobile {
              url
            }
        }
        allStrapiLatestNews(filter: {locale: {eq: "en"}, position: {lte: 4}} sort: {fields: position}) {
            edges {
                node {
                    url
                    Date
                    Image {
                        url
                    }
                    Title
                }
            }
        }
        strapiAboutCompany(locale: {eq: "en"}) {
            Title
            Text
            Image {
                url
            }
        }
        strapiBusinessPortfolioComponent(locale: {eq: "en"}) {
            Title
            Text
        }
        allStrapiSectors(filter: {locale: {eq: "en"}}, sort: {fields: position}) {
            edges {
                node {
                    Image {
                        url
                    }
                    Title
                }
            }
        }
        allStrapiBusinesses(sort: {fields: position}) {
            edges {
                node {
                    Image {
                        url
                    }
                }
            }
        }
    }
`;
