import React from 'react'
import styled from 'styled-components'

import H2 from '/src/components/library/H2'
import H4 from '/src/components/library/H4'
import Body from '/src/components/library/Body'
import Button from '/src/components/library/Button'

import bgImg from '/static/images/latest-news/bg.jpg'
import {navigate} from "gatsby";
//import {getFilterStatement} from "gatsby/dist/datastore/common/query";

const LatestNews = ({data}) => {

    return (
        <StyledLatestNews img={bgImg}>
            <Bounds>
                <Head>
                    <H2>Latest News</H2>
                    <Button label={'See All News'} onClick={() => navigate('/news')}/>
                </Head>
                <Container>
                    <Highlight href={data[0].node.url} target={'_blank'}>
                        <Image img={`${process.env.GATSBY_STRAPI_API_URL}${data[0].node.Image.url}`}/>
                        <H2>{data[0].node.Title}</H2>
                        <Body>{data[0].node.Date}</Body>
                    </Highlight>
                    {/*<HighlightTitle href={data[0].node.url} target={'_blank'}>*/}
                    {/*</HighlightTitle>*/}
                    <Aside>
                        {
                            data.map((news, index) => {
                                return (
                                    <>
                                        {
                                            index !== 0 ?
                                                <News href={news.node.url} target={'_blank'} key={index}>
                                                    <Titles>
                                                        <H4>{news.node.Title}</H4>
                                                        <Body>{news.node.Date}</Body>
                                                    </Titles>
                                                    <Thumbnail
                                                        img={`${process.env.GATSBY_STRAPI_API_URL}${news.node.Image.url}`}/>
                                                </News> :
                                                <></>
                                        }
                                    </>
                                )
                            })
                        }
                    </Aside>
                </Container>
                {/*<HighlightTitleD href={data[0].node.url} target={'_blank'}>*/}
                {/*    <H2>{data[0].node.Title}</H2>*/}
                {/*    <Body>{data[0].node.Date}</Body>*/}
                {/*</HighlightTitleD>*/}
            </Bounds>
        </StyledLatestNews>
    )
}

export default LatestNews

const StyledLatestNews = styled.div`
  background-image: url(${({img}) => img});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
`

const Bounds = styled.div`
  width: 100%;
  max-width: ${({theme}) => theme.layout.xxLargeScreen};
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 5rem 2.4rem;
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    padding: 4rem 2.4rem;
  }
    
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding: 2.4rem 2.4rem;
  }
    //max-width: ${({theme}) => theme.layout.xxLargeScreen};
`

const Container = styled.div`
  display: flex;
  margin-bottom: 3rem;
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    flex-direction: column;
    margin-bottom: 0;
  }
`

// const HighlightTitleD = styled.a`
//   display: flex;
//   gap: 1rem;
//   flex-direction: column;
//
//   p {
//     opacity: .5;
//   }
//
//   @media ${({theme}) => theme.breakpoints.tablets_reverse} {
//     display: none;
//   }
// `

const Head = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 2.4rem;
    gap: 1.4rem;
  }
`

const Highlight = styled.a`
  display: flex;
  flex: 5;
  flex-direction: column;
  margin-right: 5.8rem;

  h2 {
    max-width: 71.6rem;
    margin-bottom: 1rem;
  }

  p {
    opacity: .5;
  }

  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    margin-right: 0;
    margin-bottom: 5.4rem;
    h2 {
      max-width: unset;
    }
  }

  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-bottom: 2.4rem;
  }
`

const Aside = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
`

const Image = styled.div`
  background-image: url(${({img}) => img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
  margin-bottom: 3rem;
  // width: 723px;
  height: calc(.5 * (100vh));
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    width: 100%;
    // height: calc(.66 * (100vw - 6.4rem));
  }
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-bottom: 1.4rem;
  }
`

const News = styled.a`
  display: flex;
  justify-content: space-between;
  gap: 5rem;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  border-bottom: 1px solid #2E2C3566;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    gap: 0;
  }

  @media ${({theme}) => theme.breakpoints.mobile} {
    padding-top: 1.4rem;
    padding-bottom: 1.4rem;
  }
`

const Titles = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: 1.4rem;
    max-lines: 3;
    //max-width: 46rem;
  }

  p {
    opacity: .5;
  }

  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    margin-right: 8.3rem;
  }

  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-right: 0;
  }
`

const Thumbnail = styled.div`
  background-image: url(${({img}) => img});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
  min-width: 102px;
  height: 81px;
  @media ${({theme}) => theme.breakpoints.desktops_reverse} {
    min-width: 160px;
  }

  @media ${({theme}) => theme.breakpoints.mobile} {
    display: none;
  }
`
