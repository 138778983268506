import React from 'react'
import styled from 'styled-components'
import Button2 from "../library/Button2.js";
import H1 from "../library/H1.js"
import Caption from "../library/Caption.js"
import {Link, navigate} from "gatsby";

const Hero = ({path, img, strapiHomePage, black}) => {
    return (
        <HeroStyle img={img} black={black}>
          <HeroWrapper>
              <Caption>
                  {strapiHomePage.breadcrumb ?
                      strapiHomePage.breadcrumb.map((elem, key) => {
                          if (elem.url)
                              return (
                                  <Link key={key} to={elem.url}>{elem.label} / </Link>
                              )
                          return elem.label
                      }) : strapiHomePage.subtitle
                  }
              </Caption>
            <H1Wrapper>
              <H1>{strapiHomePage.title}</H1>
              <H1>{strapiHomePage.title2}</H1>
              <H1>{strapiHomePage.title3}</H1>
            </H1Wrapper>
            { path === "/" &&
              <H1Wrapper>
                <Button2 label={'Learn More'} onClick={() => navigate('/about-us')}/>
              </H1Wrapper>
            }
            {/*<Img src={arrow} alt=""/>*/}
          </HeroWrapper>
        </HeroStyle>
    )
}

const H1Wrapper = styled.div`
  margin-left: -2px;
  // max-width: 57rem;
`

const HeroWrapper = styled.div`
    max-width: ${({ theme }) => theme.layout.xxLargeScreen};
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 0 2.4rem;
    gap: 3rem;
  a {
    color: white;
  }
      padding: 17rem 2.4rem 3rem 2.4rem;
    @media ${({theme}) => theme.breakpoints.tablets_reverse} {
      padding: 10rem 2.4rem 2rem 2.4rem;
    }
`

const HeroStyle = styled.div`
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: calc(100vh);
  color: ${({theme, black}) => black ? theme.colors.black : theme.colors.white};
  display: flex;
  justify-content: center;

`
export default Hero

