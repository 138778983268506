import React from 'react';
import styled from 'styled-components'

const Body = ({children, className}) => {
  return (
    <BodyStyled className={className}>
      {children}
    </BodyStyled>
  );
};

export default Body;

const BodyStyled = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-family: ${({ theme }) => theme.fonts.regular};
  font-weight: 400;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: ${({ theme }) => theme.fonts.regular};
    font-weight: 400;
  }
`
