import React, {useEffect} from 'react'
import styled from 'styled-components'
import SectorCard from "/src/components/sector-card/SectorCard";

const Sector = ({ active, sector }) => {

    useEffect(() => {
    })
    return (
        <StyledSector active={active}>
            <FirstRow>
                <SectorCard img={`${process.env.GATSBY_STRAPI_API_URL}${sector[0].node.Image.url}`} txt={sector[0].node.Title}/>
                <SectorCard img={`${process.env.GATSBY_STRAPI_API_URL}${sector[1].node.Image.url}`} txt={sector[1].node.Title}/>
                <SectorCard img={`${process.env.GATSBY_STRAPI_API_URL}${sector[2].node.Image.url}`} txt={sector[2].node.Title}/>
            </FirstRow>
            <SecondRow>
                <SectorCard img={`${process.env.GATSBY_STRAPI_API_URL}${sector[3].node.Image.url}`} txt={sector[3].node.Title}/>
                <SectorCard img={`${process.env.GATSBY_STRAPI_API_URL}${sector[4].node.Image.url}`} txt={sector[4].node.Title}/>
            </SecondRow>
        </StyledSector>
    )
}

export default Sector

const StyledSector = styled.div`
  width: 100%;
  margin-bottom: 2.4rem;
  display: ${({ active }) => active ? 'flex' : 'none'};
  flex-direction: column;
  gap: 2.5rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    gap: 1rem;
  }
`

const FirstRow = styled.div`
  display: flex;
  gap: 2.5rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex-direction: column;
    gap: 1rem;
  }
`

const SecondRow = styled.div`
  display: flex;
  gap: 2.5rem;
  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex-direction: column;
    gap: 1rem;
  }
`
